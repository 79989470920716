@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar::-webkit-scrollbar {
	width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
	width: 5px;
	background-color: transparent;
}

*::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: #377dff;
	border-radius: 5px;
	width: 5px;
	cursor: pointer;
}

.scroll-hidden::-webkit-scrollbar {
	width: 0;
}

.close-btn ion-icon {
	font-size: 24px;
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

/* .ant-table-row .ant-table-cell:nth-child(1) {
    width: 300px;
} */

.-translate-x-100 {
	transform: translateX(-100%);
}
.css-s9e6pd {
	border: none !important;
	width: 100% !important;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-overlay .modal {
	position: relative !important;
	min-height: 500px;
	max-height: 768px;
	background: #fff;
	border-radius: 8px;
	padding: 20px 30px;
	width: 550px;
}

.modal-overlay .modal.fullScreen {
	max-width: 100% !important;
	width: 100% !important;
	height: 100vh !important;
	max-height: 100vh !important;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.modal-overlay .modal.fullScreen .modal__content {
	max-width: 100% !important;
	width: 100% !important;
	height: 100vh !important;
	max-height: 90vh !important;
}

.modal-overlay .modal.large {
	width: 1000px;
}

.modal-overlay .modal.xl {
	width: 1000px;
}

@media screen and (max-width: 1000px) {
	.modal-overlay .modal.large {
		width: 100% !important;
	}
}

.modal-overlay .modal .modal__heading {
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}

.modal-overlay .modal .modal__content {
	min-height: 360px;
	max-height: 500px;
	overflow-y: auto;
	width: 100%;
}

.modal-overlay .modal .modal__footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px 0;
}

@media screen and (max-width: 500px) {
	.modal-overlay .modal {
		width: 100% !important;
		height: 100% !important;
		margin: 0 !important;
	}

	.modal-overlay .modal .modal__heading {
		font-size: 18px;
		line-height: 22px;
	}

	.modal-overlay .modal .modal__content {
		height: auto;
	}

	.modal-overlay .modal .modal__footer {
		padding: 0;
	}
}

.form-control {
	width: 100%;
	padding: 5px 15px;
	background-color: #f5f6fa !important;
	border-radius: 4px;
	box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.01);
	border: none;
	outline: none;
}

.form-control__multiselect * {
	outline: none;
	background-color: #f5f6fa !important;
	border: none !important;
}

.form-control__multiselect input::placeholder {
	color: #c4c4c4 !important;
}

.form-control[type='file']::-webkit-file-upload-button {
	background-color: #f5f6fa;
	border: none;
	border-radius: 4px;
	outline: none;
	padding: 7.5px 15px;
}

.form-control[type='file'] {
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 2px solid #f5f6fa;
	border-radius: 4px;
	box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.01);
	outline: none;
}

.form-control::placeholder {
	color: #c4c4c4;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
}

.form-control:focus {
	outline: 1px solid #377dff;
}

.focus-border:focus {
	outline: 1px solid #377dff;
}

.form-label {
	padding: 0 2px;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.form-label .form-label__text {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 10px;
}

.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
	display: flex;
	align-items: center;
	cursor: grabbing;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	opacity: 0.9;
	justify-content: space-between;
	width: 40rem !important;
}

.row-dragging td {
	padding: 16px;
}

.row-dragging .drag-visible {
	visibility: visible;
}

@media screen and (max-width: 500px) {
	.row-dragging {
		background: #fafafa;
		border: 1px solid #ccc;
		display: flex;
		align-items: center;
		cursor: grabbing;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
		opacity: 0.9;
		justify-content: space-between;
		width: 80% !important;
	}
}

.text-red {
	color: red;
}

.color {
	display: flex;
	gap: 10px;
	align-items: center;
}

.color .green {
	width: 20px;
	height: 20px;
	background-color: green;
	border-radius: 50%;
}

.color .red {
	width: 20px;
	height: 20px;
	background-color: red;
	border-radius: 50%;
}

.color .yellow {
	width: 20px;
	height: 20px;
	background-color: yellow;
	border-radius: 50%;
}

.color .cyan {
	width: 20px;
	height: 20px;
	background-color: cyan;
	border-radius: 50%;
}

.color .blue {
	width: 20px;
	height: 20px;
	background-color: blue;
	border-radius: 50%;
}

.color .grey {
	width: 20px;
	height: 20px;
	background-color: grey;
	border-radius: 50%;
}

.relative {
	position: relative !important;
}

.cyan-bg {
	background-color: #00ffff;
}
